import { rogueMechData } from '../data/game-data'
import MoreInfoButton from './buttons/MoreInfoButton'
import { GetLienucImageURL } from '../helpers/GetGoogleDriveImageURL'
import RogueMechHomePhoto from '../resources/images/products/roguemech-home4.jpg'

function HomeMainCard() {
    return (
        <div className='relative bg-black'>
            <img className='m-auto block max-w-full max-h-full w-auto h-auto'
                crossorigin="anonymous"
                src={RogueMechHomePhoto}
                alt='Rogue Mechs'
            />
            <div className='absolute bottom-0 left-0 w-full h-36 bg-black opacity-60'/>
            <div className='absolute bottom-0 left-0 px-10 w-full h-36 flex justify-between items-center'>
                <p className='text-6xl font-bold text-white'>Coming Soon</p>
                <MoreInfoButton to={rogueMechData.to} state={rogueMechData.state}/>
            </div>
        </div>
    )
}

export default HomeMainCard

import ApacheQuestGameCardImage from '../resources/images/products/apachexr-latestGameCardImage.jpg'
import ApacheBigGameCardImage from '../resources/images/products/apachexr-bigCardImage.jpg'
import ApacheDetailsImage from '../resources/images/products/apachexr-details.jpg'
import AirhoundGameCardImage from '../resources/images/products/airhound1b-latestGameCardImage.jpg'
import AirhoundBigGameCardImage from '../resources/images/products/airhound1b-bigCardImage.jpg'
import AirhoundDetailsImage from '../resources/images/products/airhound1b-details.jpg'
import CMTGameCardImage from '../resources/images/products/cmt-latestGameCardImage.jpg'
import CMTBigGameCardImage from '../resources/images/products/cmt-bigCardImage.jpg'
import CMTDetailsImage from '../resources/images/products/cmt-details.jpg'
import RogueMechBigGameCardImage from '../resources/images/products/roguemechs-bigCardImage.jpg'
import RogueMechDetailsImage from '../resources/images/products/roguemechs-details.jpg'

export const apacheQuestData = {
    title: 'Apache Quest XR',
    desc: 'Get ready to take to the skies like never before in Apache Quest XR!',
    descGamePage: 'This mixed reality game puts you in control of a virtual remote-controlled helicopter, giving you the thrill of flying a real aircraft in the comfort of your own space. With the Meta Quest 3 VR headset, see your helicopter come to life in stunning detail, and use your controllers to guide it through challenging obstacle courses.',
    to: '/details/apache-quest',
    availability: 'COMING SOON ON',
    latestGameCardImageId: '1T4-ovQfMd413z7ejm0fJoWXzmmmUPM8G',
    latestGameCardImage: ApacheQuestGameCardImage,
    bigGameCardImage: ApacheBigGameCardImage,
    isMobileGame: false,
    state: {
        title: 'Apache Quest XR',
        caption: 'Experience the ultimate mixed reality flying adventure!',
        desc: `Apache Quest XR is a revolutionary mixed reality game that puts you in the pilot's seat of a virtual remote-controlled helicopter, delivering an unparalleled flying experience. With the Meta Quest 3 VR headset, you'll be transported to a stunning, immersive environment where your helicopter comes to life in breathtaking detail.

        Using the Quest 3's advanced controllers, guide your aircraft through challenging obstacle courses, navigate treacherous terrain, and engage in high-speed dogfights against rival helicopters. The game's cutting-edge physics engine simulates the realistic movements and handling of a real helicopter, making every maneuver feel authentic and exhilarating.

        With multiple game modes, including Time Attack, Race, and Combat, there's always a new challenge waiting for you. Compete with friends and foes alike to earn rewards, upgrade your helicopter, and unlock new levels and game modes.

        Whether you're a seasoned gamer or just looking for a new adventure, Apache Quest XR offers an unforgettable experience that will leave you feeling like a true helicopter pilot. So, buckle up, take to the skies, and get ready for the ultimate thrill ride!`,
        availability: 'COMING SOON ON',
        imageId: '1bGwOBdyDiGkMzucr6-e4CbWyLFA9zD_R',
        image: ApacheDetailsImage,
        urlLink: 'https://www.meta.com/experiences/section/7552117818138723/?utm_source=exp_hub_nav&utm_medium=self_referral%2F',
        buttonTitle: 'Meta Store',
        isMobileGame: false
    }
}

export const rogueMechData = {
    title: 'Rogue Mech Invasion',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod',
    descGamePage: `Defend your home from an army of rogue mechs that have invaded your living space! In this immersive mixed reality game, small spider-like mechs with four legs will crawl out of your walls, floors, and furniture to attack you. Can you survive the Rogue Mechs Home Invasion?`,
    to: '/details/rogue-mech-invasion',
    availability: 'COMING SOON ON',
    latestGameCardImageId: '1Xd0OPW6DA6mZVqelOYNftSvlukhOzQgI',
    latestGameCardImageId: '1wETfbrAoCgQ90J2bfCmKp3GrvcB8lXcS',
    bigGameCardImage: RogueMechBigGameCardImage,
    isMobileGame: false,
    state: {
        title: 'Rogue Mech Invasion',
        caption: 'Your Home. Their Target. Defend It!',
        desc: `"Rogue Mechs Home Invasion" is a heart-pounding, immersive mixed reality game that brings the battle to your doorstep - literally. In this thrilling experience, your living room, bedroom, or any other room in your home becomes the battleground.

        The game begins with a mysterious warning: rogue mechs have infiltrated your home's infrastructure, and they're determined to take over. These small, spider-like mechs with four legs are agile, relentless, and will stop at nothing to claim your space as their own.
        
        As the game starts, you'll see mechs emerging from the walls, floors, and furniture. They'll crawl, jump, and scurry across your room, requiring you to think and act fast to take them down. Your goal is to defend your home and eliminate the mechs before they overwhelm you.

        To aid you in this fight, you'll have access to a variety of weapons and tools, including:
        Traps: Set up traps to immobilize or destroy mechs
        Turrets: Deploy automated turrets to take out mechs from a distance
        Drones: Launch drones to scout and attack mechs
        Upgrades: Improve your weapons and tools as you progress through levels

        As you progress through the game, the mechs will become increasingly aggressive and intelligent, adapting to your tactics and requiring you to evolve your strategy to succeed. You'll need to use your surroundings to your advantage, exploiting the mechs' weaknesses and leveraging your environment to outsmart them.

        With its cutting-edge mixed reality technology, "Rogue Mechs Home Invasion" blurs the lines between the physical and digital worlds, creating an unforgettable gaming experience that will leave you breathless and eager for more. So, are you ready to defend your home and take on the rogue mechs? 
        
        The battle is on!`,
        availability: 'COMING SOON ON',
        imageId: '1jwoojxnxt2iOc1-Zlicv7l1oAny4zzCH',
        image: RogueMechDetailsImage,
        urlLink: 'https://www.meta.com/experiences/section/7552117818138723/?utm_source=exp_hub_nav&utm_medium=self_referral%2F',
        buttonTitle: 'Meta Store',
        isMobileGame: false
    }
}

export const clashOfMiniTanksData = {
    title: 'Clash of Mini Tanks',
    desc: 'Engage in fast-paced, action-packed battles with tiny but mighty tanks.',
    descGamePage: 'Get ready for a blast of miniaturized mayhem! In Clash of Mini Tanks, engage in fast-paced, action-packed battles with tiny but mighty tanks. Collect, upgrade, and customize your fleet, then clash with rivals in arena-style combat.',
    to: '/details/clash-of-mini-tanks',
    availability: 'AVAILABLE ON',
    latestGameCardImageId: '1ax8h5X7tf8pJ5Kn_chVttcW0EaqBqa5m',
    bigGameCardImageId: '1Jj5HRa7WB5KAug2lZizKKuj4SO7NLEWd',
    latestGameCardImage: CMTGameCardImage,
    bigGameCardImage: CMTBigGameCardImage,
    isMobileGame: true,
    state: {
        title: 'Clash of Mini Tanks',
        caption: 'Become the ultimate mini tank champion!',
        desc: `Enter a world of tiny titans in Clash of Mini Tanks, a thrilling iOS game that puts you in command of a miniature military force. Collect and upgrade a variety of tiny tanks, each with unique abilities and strengths.

        Engage in fast-paced, arena-style battles against rival tanks, using quick reflexes and clever tactics to outmaneuver and outgun your opponents.

        As you progress, unlock new tanks, weapons, and upgrades to enhance your fleet's capabilities. Join forces with friends to form powerful alliances and dominate the mini tank arena.

        With its colorful, cartoon-style graphics, addictive gameplay, and social features, Clash of Mini Tanks is the perfect game for anyone looking for a fun and competitive mobile gaming experience. So, get ready to roll out and clash with the best of them!`,
        availability: 'AVAILABLE ON',
        imageId: '1rCWt76tkGoKRxDUAKX0wKgXXeMBcSYYt',
        image: CMTDetailsImage,
        urlLink: 'https://apps.apple.com/gb/app/clash-of-mini-tanks/id1039703100',
        buttonTitle: 'App Store',
        isMobileGame: true
    }
}

export const airhoundData = {
    title: 'Airhound',
    titleGamePage: 'Airhound: Chopper Commander',
    desc: 'Take to the skies in this high-octane side-scrolling helicopter action game!',
    descGamePage: 'Take control of a high-tech helicopter and embark on a dangerous mission to collect all the nukes in the area. Fly through treacherous terrain, avoid enemy fire, and overcome obstacles in this action-packed 2.5D side-scrolling game.',
    to: '/details/airhound-chopper-commander',
    availability: 'AVAILABLE ON',
    latestGameCardImageId: '1bTTvP575W7JS2Q-VQDKv5E-gioq-rgjR',
    bigGameCardImageId: '1qPGIKWg-YiEbfFp9dTeQ4TTo83htP8I_',
    latestGameCardImage: AirhoundGameCardImage,
    bigGameCardImage: AirhoundBigGameCardImage,
    isMobileGame: true,
    state: {
        title: 'Airhound',
        caption: 'Fly, fight, and dominate the skies!',
        desc: `Get ready to take to the skies in Airhound: Chopper Commander, a heart-pumping 2.5D side-scrolling helicopter game that puts your piloting skills to the test. Your mission is to gather all the nukes in the area, and you'll need to navigate through treacherous terrain to succeed.

        Fly your chopper through dense jungles, scorching deserts, and snow-capped mountains, avoiding obstacles and enemy fire as you go. With a variety of helicopters to choose from, each with its own unique abilities and upgrades, you'll need to use your skills and strategy to outmaneuver your foes and collect all the nukes.

        As you progress through levels, you'll encounter challenging boss battles, treacherous terrain, and unexpected surprises. But with your quick reflexes and sharp instincts, you'll be able to overcome any obstacle and complete your mission.

        With its stunning 2.5D graphics, fast-paced action, and addictive gameplay, Airhound: Chopper Commander is the perfect game for anyone looking for a thrilling and challenging experience. So, buckle up, pilot, and get ready to take on the skies like never before!
   
        Download Airhound: Chopper Commander now and start your mission to gather all the nukes and save the day!`,
        availability: 'AVAILABLE ON',
        imageId: '1EuDmtX878tY3yaxzuNbhSH68XQphxUm4',
        image: AirhoundDetailsImage,
        urlLink: 'https://apps.apple.com/gb/app/airhound-chopper-commander/id838090504',
        buttonTitle: 'App Store',
        isMobileGame: true
    }
}

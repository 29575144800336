import LearnMoreButton from "../buttons/LearnMoreButton"
import { GetLienucImageURL } from "../../helpers/GetGoogleDriveImageURL"
import AppleIcon from '../../resources/images/apple-icon.png'
import MetaQuestIcon from '../../resources/images/meta-quest-icon.png'
import { useEffect, useState } from "react"

function BigGameCard({title, desc, availability, isMobileGame, to, imageId, image, state}) {

    const [devices, setDevices] = useState('')
    const [icon, setIcon] = useState(AppleIcon)

    useEffect(() => {
        if (isMobileGame === true) {
            setDevices('iPhone & iPad')
        } else {
            setDevices('Meta Quest 2 & 3')
            setIcon(MetaQuestIcon)
        }
    }, [])

    function renderPlatforms(){
        return (
            <div className="mt-2 flex">
                <div className="mr-10 flex items-center">
                    <img src={icon} alt='platform logo' />
                    <p className="ml-3">{devices}</p>
                </div>
            </div>
        )
    }

    return (
        <div className="w-full p-4 my-1 flex justify-between bg-white">
            <img className="w-[500px] max-h-[300px]"
                crossorigin="anonymous"
                src={image}
                alt={title}
            />
            <div className="ml-5">
                <section className="text-gray-600">
                    <h2 className="mb-4 text-2xl font-bold">{title}</h2>
                    <p className="whitespace-pre-line text-sm font-normal">{desc}</p>
                </section>
                <LearnMoreButton to={to} state={state}/>
                <section className="mt-4 font-semibold text-sm text-gray-600">
                    <p>{availability}</p>
                    {renderPlatforms()}
                </section>
            </div>
        </div>
    )
}

export default BigGameCard

import { Link } from 'react-router-dom'

function HeaderButton({to, title}) {
    return (
        <Link 
            className='w-[100px] h-10 flex text-sm font-light text-white 
            hover:bg-gray-600 hover:font-semibold active:bg-amber-500
            focus:bg-gray-600 focus:font-semibold'
            to={to}
            >  
            <p className='m-auto'>
                {title}
            </p>
        </Link>
    )
}

export default HeaderButton

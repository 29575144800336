import { useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import AppleIcon from '../resources/images/apple-icon.png'
import MetaQuestIcon from '../resources/images/meta-quest-icon.png'
import DetailsGetButton from "../components/buttons/DetailsGetButton"
import { GetLienucImageURL } from "../helpers/GetGoogleDriveImageURL"

function Details() {

    const location = useLocation()
    const title = location.state.title
    const caption = location.state.caption
    const desc = location.state.desc
    const availability = location.state.availability
    const imageId = location.state.imageId
    const image = location.state.image
    const urlLink = location.state.urlLink
    const buttonTitle = location.state.buttonTitle
    const isMobileGame = location.state.isMobileGame

    const [devices, setDevices] = useState('')
    const [icon, setIcon] = useState(AppleIcon)

    useEffect(() => {
        window.scrollTo(0, 0)
        if (isMobileGame === true) {
            setDevices('iPhone & iPad')
        } else {
            setDevices('Meta Quest 2 & 3')
            setIcon(MetaQuestIcon)
        }
    }, [title])

    function renderPlatforms() {
        return (
            <div className="mt-2 flex">
                <div className="mr-10 flex items-center">
                    <img src={icon} alt='platform logo' />
                    <p className="ml-3">{devices}</p>
                </div>
            </div>
        )
    }

    return (
        <div className='w-full mb-8'>
            <div className="p-10 bg-white">
                <div className="w-full mb-5 my-1 flex">
                    <div className="w-2/5 p-4 bg-gradient-to-b from-gray-800 to-blue-400 text-white">
                        <h2 className="mb-6 text-3xl font-bold">{title}</h2>
                        <DetailsGetButton urlLink={urlLink} buttonTitle={buttonTitle}/>
                        <section className="mt-[40px] font-semibold text-sm">
                            <p>{availability}</p>
                            {renderPlatforms()}
                        </section>
                    </div>
                    <img className="w-3/5 h-[300px]"
                        crossorigin="anonymous"
                        src={image}
                        alt={title}
                    />
                </div>
                <div className="w-full my-1 flex">
                    <section>
                        <h2 className="mb-5 text-4xl font-bold text-gray-500">{caption}</h2>
                        <p className="whitespace-pre-line text-sm font-light text-gray-600">{desc}</p>
                    </section>
                </div>
            </div>
        </div>
    )
}


export default Details

import { Link } from 'react-router-dom'
import HeaderButton from './buttons/HeaderButton'
import Logo64 from '../resources/images/cmi_logo_64.png'

export default function Header(){

    return (
        <div className='items-center mb-8 sticky top-0 z-10'>
            <Link className='flex bg-gradient-to-b from-gray-500 to-gray-600' to='/'>
                <div className='m-auto h-24 flex justify-between items-center'>
                    <img className='mr-5' src={Logo64} alt='logo' />
                    <h1 className='text-4xl font-bold italic text-amber-500 drop-shadow-2xl'>Cybermash Interactive</h1>
                </div>
            </Link>

            <div className='flex items-center bg-gray-700'>
                <div className='m-auto w-[400px] flex justify-between'>
                    <HeaderButton to='/' title='Home' />
                    <HeaderButton to={{pathname: '/games' }} title='Games' />
                    <HeaderButton to={{pathname: '/support'}} title='Support' />
                    <HeaderButton to='/about' title='About Us' />
                </div>
            </div>
        </div>
    )
}

import LatestGameCard from "./LatestGameCard"
import PlatformLogo from '../../resources/images/logo_32x32.png'
import { airhoundData, apacheQuestData, clashOfMiniTanksData } from "../../data/game-data"

function LatestGamesSection() {

    return (
        <div className="mt-10 p-10 bg-white">
            <h1 className="text-3xl font-bold text-gray-500 mb-8">Latest from Cybermash</h1>
            <div className="flex justify-between">
                <LatestGameCard
                    title={apacheQuestData.title} 
                    desc={apacheQuestData.desc}
                    platforms={
                        <div className="mt-2 flex">
                            <div className="mr-10 flex items-center">
                                <img src={PlatformLogo} alt='platform logo' />
                                <p className="ml-3">iPhone & iPad</p>
                            </div>
                        </div>
                    }
                    to={apacheQuestData.to}
                    image={apacheQuestData.latestGameCardImage}
                    state={apacheQuestData.state}
                />
                <LatestGameCard 
                    title={clashOfMiniTanksData.title} 
                    desc={clashOfMiniTanksData.desc}
                    platforms={
                        <div className="mt-2 flex">
                            <div className="mr-10 flex items-center">
                                <img src={PlatformLogo} alt='platform logo' />
                                <p className="ml-3">iPhone & iPad</p>
                            </div>
                        </div>
                    }
                    to={clashOfMiniTanksData.to}
                    image={clashOfMiniTanksData.latestGameCardImage}
                    state={clashOfMiniTanksData.state}
                />
                <LatestGameCard 
                    title={airhoundData.title}
                    desc={airhoundData.desc}
                    platforms={
                        <div className="mt-2 flex">
                            <div className="mr-10 flex items-center">
                                <img src={PlatformLogo} alt='platform logo' />
                                <p className="ml-3">iPhone & iPad</p>
                            </div>
                        </div>
                    }
                    to={airhoundData.to}
                    imageId={airhoundData.latestGameCardImageId}
                    image={airhoundData.latestGameCardImage}
                    state={airhoundData.state}
                />
            </div>
        </div>
    )
}

export default LatestGamesSection

import HomeMainCard from "../components/HomeMainCard"
import LatestGamesSection from "../components/home/LatestGamesSection"
import SeeAllGames from "../components/home/SeeAllGamesSection"
import { useEffect } from 'react'

function Home() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <HomeMainCard />
            <LatestGamesSection />
            <SeeAllGames />
        </div>
    )
}

export default Home

import { Link } from 'react-router-dom'

function LearnMoreButton({to, state}) {
    return (
        <div className='w-36 h-12 mt-4 rounded-lg flex bg-blue-500 hover:bg-amber-500'>
            <Link className='w-full h-full flex' to={to} state={state}>
                <p className='m-auto text-base font-semibold text-center align-middle text-white'>LEARN MORE</p>
            </Link>
        </div>
    )
}

export default LearnMoreButton

function DetailsGetButton({urlLink, buttonTitle}) {
    return (
        <div className='h-20 mx-6 rounded-xl flex bg-amber-500 hover:bg-gray-400'>
            <a className='w-full h-full flex' href={urlLink} target="_blank">
                <p className='m-auto text-4xl font-bold text-center align-middle text-white'>{buttonTitle}</p>
            </a>
        </div>
    )
}

export default DetailsGetButton

import { useEffect } from "react"
import SupportImage from '../resources/images/products/customerSupportImage.jpeg'

function Support() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='w-full mb-8'>
            <h1 className="text-4xl font-bold text-white mb-4">From Glitches to Glory: We're Here to Help</h1>
            <div className="p-10 flex bg-white">
                <img
                    className="w-[400px] max-h-[300px]"
                    crossOrigin="anonymous"
                    src={SupportImage}
                    alt="customer support image"
                />
                <p className="w-3/4 ml-5 text-lg font-normal text-gray-600">
                    At Cybermash Interactive, we are deeply committed to creating games that are not only enjoyable and challenging but also meet exceptionally 
                    high standards. We invest significant time in testing our games before release, but occasionally, a few bugs may evade detection. 
                    If you encounter an issue or discover a bug, please notify us, and we will strive to address the problem in our next update.
                </p>
            </div>
        </div>
    )
}

export default Support

import { Link } from 'react-router-dom'

function SeeAllGames() {
    return (
        <div className='h-[64px] mb-[30px] flex bg-gray-400 hover:bg-amber-500'>
            <Link className='w-full h-full flex' to='/games'>
                <p className='m-auto text-2xl font-medium text-center align-middle text-white'>View All Games</p>
            </Link>
        </div>
    )
}

export default SeeAllGames

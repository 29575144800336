import 'bulma/css/bulma.css'
import './css/App.css'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import HomePage from './pages/Home'
import AboutPage from './pages/AboutUs'
import DetailsPage from './pages/Details'
import GamesPage from './pages/Games'
import SupportPage from './pages/Support'
import RootPage from './pages/Root'
import TermsOfUse from './pages/TermsOfUse'
import PrivacyPolicy from './pages/PrivacyPolicy'

const headerButtons = [
    {title: "Games"}, {title: "News"}, {title: "Support"}, {title: "About Us"}
]

const router = createBrowserRouter([
    {
        path: '/',
        element: <RootPage />,
        children: [
            {
                index: true,
                element: <HomePage />
            },
            {
                path: '/games',
                element: <GamesPage />
            },
            {
                path: '/about',
                element: <AboutPage />
            },
            {
                path: '/termsofuse',
                element: <TermsOfUse />
            },
            {
                path: '/privacy-policy',
                element: <PrivacyPolicy />
            },
            {
                path: '/details/:title',
                element: <DetailsPage />
            },
            {
                path: '/support',
                element: <SupportPage />
            }
        ]
    }
])

function App() {

    // const renderHeaderButtons = headerButtons.map((button, index) => {
    //     return <HeaderButton title={button.title} key={index}/>
    // })

    return <RouterProvider router={router} />
}

export default App

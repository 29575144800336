import { useEffect } from "react"

function PrivacyPolicy() {
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return (
        <div className='w-full mb-8'>
            <h1 className="text-4xl font-bold text-white mb-4">Privacy Policy</h1>
            <div className="p-10 bg-white">
                <p className="mb-10 text-base font-medium text-gray-700">
                    This Privacy Policy governs the manner in which Cybermash Interactive, Inc. collects, uses, 
                    maintains and discloses information collected from users (each, a “User”) of the cybermash-interactive.ca website (“Site”) 
                    and our software products across multiple platforms (“Games”). This privacy policy applies to the Site 
                    and all products and services offered by Cybermash Interactive, Inc.
                </p>
                <section className="mb-5">
                    <p className="text-lg mb-3 font-semibold text-black">Personal identification information</p>
                    <p className="text-sm font-normal text-gray-600">
                        We may collect personal identification information from Users in a variety of ways, including,
                        but not limited to, when Users visit our site, fill out a form, and in connection with other activities,
                        services, features or resources we make available on our Site. Users may be asked for, as appropriate, name,
                        email address. Users may, however, visit our Site anonymously. We will collect personal identification
                        information from Users only if they voluntarily submit such information to us, such as when requesting
                        technical support for our Games. Users can always refuse to supply personally identification information,
                        except that it may prevent them from engaging in certain Site related activities.
                    </p>
                </section>
                <section className="mb-5">
                    <p className="text-lg mb-3 font-semibold text-black">Non-personal identification information</p>
                    <p className="text-sm font-normal text-gray-600">
                    We may collect non-personal identification information about Users whenever they interact with our Site. 
                    Non-personal identification information may include the browser name, the type of computer and technical 
                    information about Users means of connection to our Site, such as the operating system and the Internet 
                    service providers utilized and other similar information.  Non-personal identification information may be 
                    transmitted when Users interact with our Games, such as operating system versions, hardware specifications, 
                    and anonymous hardware identifiers.  These may be used for the purpose of submitting crash reports and 
                    usage data to help us improve our Games, or to provide additional services via third parties, such as 
                    cloud storage of our Users’ game progress.  For our Games which utilize Google Play Game Services for 
                    cloud storage, please consult Google’s privacy policy for further details regarding how they handle your 
                    information.
                    </p>
                </section>
                <section className="mb-5">
                    <p className="text-lg mb-3 font-semibold text-black">Web browser cookies</p>
                    <p className="text-sm font-normal text-gray-600">
                    Our Site may use “cookies” to enhance User experience. User’s web browser places cookies on their 
                    hard drive for record-keeping purposes and sometimes to track information about them. User 
                    may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. 
                    If they do so, note that some parts of the Site may not function properly.
                    </p>
                </section>
                <section className="mb-5">
                    <p className="text-lg mb-3 font-semibold text-black">How we protect your information</p>
                    <p className="text-sm font-normal text-gray-600">
                    We adopt appropriate data collection, storage and processing practices and security measures to protect 
                    against unauthorized access, alteration, disclosure or destruction of your personal information, 
                    username, password, transaction information and data stored on our Site.
                    </p>
                </section>
                <section className="mb-5">
                    <p className="text-lg mb-3 font-semibold text-black">Sharing your personal information</p>
                    <p className="text-sm font-normal text-gray-600">
                    We do not sell, trade, or rent Users personal identification information to others. We may share generic 
                    aggregated demographic information not linked to any personal identification information regarding 
                    visitors and users with our business partners, trusted affiliates and advertisers for the 
                    purposes outlined above.
                    </p>
                </section>
                <section className="mb-5">
                    <p className="text-lg mb-3 font-semibold text-black">Compliance with children’s online privacy protection act</p>
                    <p className="text-sm font-normal text-gray-600">
                    Protecting the privacy of the very young is especially important. For that reason, we never collect or 
                    maintain information at our Site from those we actually know are under 13, and no part of our website 
                    is structured to attract anyone under 13.
                    </p>
                </section>
                <section className="mb-5">
                    <p className="text-lg mb-3 font-semibold text-black">Changes to this privacy policy</p>
                    <p className="text-sm font-normal text-gray-600">
                    Cybermash Interactive, Inc. has the discretion to update this privacy policy at any time. When we do, we will 
                    revise the updated date at the bottom of this page. We encourage Users to frequently check this page 
                    for any changes to stay informed about how we are helping to protect the personal information we collect. 
                    You acknowledge and agree that it is your responsibility to review this privacy policy periodically and 
                    become aware of modifications.
                    </p>
                </section>
                <section className="mb-8">
                    <p className="text-lg mb-3 font-semibold text-black">Your acceptance of these terms</p>
                    <p className="text-sm font-normal text-gray-600">
                    By using this Site and our Games, you signify your acceptance of this policy. If you do not agree to this 
                    policy, please do not use our Site or Games. Your continued use of the Site/Games following the posting of 
                    changes to this policy will be deemed your acceptance of those changes.
                    </p>
                </section>
                <p className="text-sm font-normal text-gray-600">
                    This document was last updated on May 24, 2023
                </p>
            </div>
        </div>
    )
}

export default PrivacyPolicy

import { useEffect } from "react"

function TermsOfUse() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='w-full mb-8'>
            <h1 className="text-4xl font-bold text-white mb-4">Web Site Terms and Conditions of Use</h1>
            <div className="p-10 bg-white">
                <section className="mb-5">
                    <p className="text-lg mb-3 font-semibold text-black">1. Terms</p>
                    <p className="text-sm font-normal text-gray-600">
                        By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this web site are protected by applicable copyright and trade mark law.<br/><br/>
                        You must be at least 13 years or older to register and use this web site. If you are under the age of 13, you must have the explicit permission of a parent or guardian.
                    </p>
                </section>
                <section className="mb-5">
                    <p className="text-lg mb-3 font-semibold text-black">2. Use License</p>
                    <p className="text-sm font-normal text-gray-600">
                        Permission is granted to temporarily download one copy of the materials (information or software) on Cybermash Interactive, Inc.’s web site for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not: modify or copy the materials; use the materials for any commercial purpose, or for any public display (commercial or non-commercial); attempt to decompile or reverse engineer any software contained on Cybermash Interactive, Inc.’s web site; remove any copyright or other proprietary notations from the materials; or transfer the materials to another person or “mirror” the materials on any other server.<br/><br/>
                        This license shall automatically terminate if you violate any of these restrictions and may be terminated by Cybermash Interactive, Inc. at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
                    </p>
                </section>
                <section className="mb-5">
                    <p className="text-lg mb-3 font-semibold text-black">3. Disclaimer</p>
                    <p className="text-sm font-normal text-gray-600">
                        The materials on Cybermash Interactive, Inc.’s web site are provided “as is”. Cybermash Interactive, Inc. makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, Cybermash Interactive, Inc. does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its Internet web site or otherwise relating to such materials or on any sites linked to this site.
                    </p>
                </section>
                <section className="mb-5">
                    <p className="text-lg mb-3 font-semibold text-black">4. Limitations</p>
                    <p className="text-sm font-normal text-gray-600">
                        In no event shall Cybermash Interactive, Inc. or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption,) arising out of the use or inability to use the materials on Cybermash Interactive, Inc.’s Internet site, even if Cybermash Interactive, Inc. or a Cybermash Interactive, Inc. authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
                    </p>
                </section>
                <section className="mb-5">
                    <p className="text-lg mb-3 font-semibold text-black">5. Revisions and Errata</p>
                    <p className="text-sm font-normal text-gray-600">
                        The materials appearing on Cybermash Interactive, Inc.’s web site could include technical, typographical, or photographic errors. Cybermash Interactive, Inc. does not warrant that any of the materials on its web site are accurate, complete, or current. Cybermash Interactive, Inc. may make changes to the materials contained on its web site at any time without notice. Cybermash Interactive, Inc. does not, however, make any commitment to update the materials.
                    </p>
                </section>
                <section className="mb-5">
                    <p className="text-lg mb-3 font-semibold text-black">6. Links</p>
                    <p className="text-sm font-normal text-gray-600">
                        Cybermash Interactive, Inc. has not reviewed all of the sites linked to its Internet web site and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Cybermash Interactive, Inc. of the site. Use of any such linked web site is at the user’s own risk.
                    </p>
                </section>
                <section className="mb-5">
                    <p className="text-lg mb-3 font-semibold text-black">7. Site Terms of Use Modifications</p>
                    <p className="text-sm font-normal text-gray-600">
                        Cybermash Interactive, Inc. may revise these terms of use for its web site at any time without notice. By using this web site you are agreeing to be bound by the then current version of these Terms and Conditions of Use.
                    </p>
                </section>
                <section>
                    <p className="text-lg mb-3 font-semibold text-black">8. Governing Law</p>
                    <p className="text-sm font-normal text-gray-600">
                        Any claim relating to Cybermash Interactive, Inc.’s web site shall be governed by the laws of the Province of British Columbia without regard to its conflict of law provisions.
                    </p>
                </section>
            </div>
        </div>
    )
}

export default TermsOfUse

import { useEffect } from 'react'
import BigGameCard from '../components/gamespage/BigGameCard'
import PlatformLogo from '../resources/images/logo_32x32.png'
import { airhoundData, apacheQuestData, clashOfMiniTanksData, rogueMechData } from '../data/game-data'

function Games() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return ( 
        <div className='w-full mb-8'>
            <h1 className="text-4xl font-bold text-white mb-4">Level Up Your Fun: Explore Our Games</h1>
            <BigGameCard 
                title={rogueMechData.title}
                desc={rogueMechData.descGamePage}
                availability={rogueMechData.availability}
                isMobileGame={rogueMechData.isMobileGame}
                to={rogueMechData.to}
                image={rogueMechData.bigGameCardImage}
                state={rogueMechData.state}
            />
            <BigGameCard 
                title={apacheQuestData.title} 
                desc={apacheQuestData.descGamePage}
                availability={apacheQuestData.availability}
                isMobileGame={apacheQuestData.isMobileGame}
                to={apacheQuestData.to}
                image={apacheQuestData.bigGameCardImage}
                state={apacheQuestData.state}
            />
            <BigGameCard 
                title={clashOfMiniTanksData.title} 
                desc={clashOfMiniTanksData.descGamePage}
                availability={clashOfMiniTanksData.availability}
                isMobileGame={clashOfMiniTanksData.isMobileGame}
                to={clashOfMiniTanksData.to}
                image={clashOfMiniTanksData.bigGameCardImage}
                state={clashOfMiniTanksData.state}
            />
            <BigGameCard 
                title={airhoundData.titleGamePage} 
                desc={airhoundData.descGamePage}
                availability={airhoundData.availability}
                isMobileGame={airhoundData.isMobileGame}
                to={airhoundData.to}
                image={airhoundData.bigGameCardImage}
                state={airhoundData.state}
            />
        </div>
    )
}

export default Games

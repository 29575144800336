import { Link } from 'react-router-dom'

function MoreInfoButton({to, state}) {
    return (
        <div className='w-48 h-20 rounded-xl flex bg-blue-500 hover:bg-amber-500'>
            <Link className='w-full h-full flex' to={to} state={state}>
                <p className='m-auto text-2xl font-bold text-center align-middle text-white'>MORE INFO</p>
            </Link>
        </div>
    )
}

export default MoreInfoButton

import { Outlet } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'

function Root() {

    return (
        <div className='m-auto bg-gradient-to-b from-gray-500 to-gray-300'>
            <Header />
            <div className="m-auto w-3/4 max-w-5xl flex justify-between items-center"><Outlet /></div>
            <Footer />
        </div>
  
    )
}

export default Root

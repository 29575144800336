import { useEffect } from "react"
import { GetLienucImageURL } from "../helpers/GetGoogleDriveImageURL"
import AboutUsImage from '../resources/images/products/ourhistoryImage.jpg'

function AboutUs() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='w-full mb-8'>
            <h1 className="text-4xl font-bold text-white mb-4">Nostalgic fun, next-gen performance.</h1>
            <div className="p-10 flex justify-between bg-white">
                <section className="w-[400px]">
                    <img 
                        crossorigin="anonymous" 
                        src={AboutUsImage}
                        alt="our history robot"
                    />
                    <div className="p-4 text-white bg-gradient-to-b from-gray-800 to-blue-400">
                        <p className="mb-5 text-lg font-bold">Our History</p>
                        <p className="text-sm font-light">
                            Cybermash Interactive (CMI) was founded by a seasoned team of developers and artists who have been crafting innovative mobile and PC apps since 2012. With a passion for creating engaging experiences, they have consistently pushed the boundaries of game development.<br /><br />
                            Following the unexpected success of their debut mobile title, Airhound: Chopper Commander, the team has continued to raise the bar with each new project, showcasing their dedication to quality and creativity. Their critically acclaimed game, Clash of Mini Tanks, is a testament to their commitment to delivering exceptional gaming experiences. With a proven track record and a drive to innovate, CMI is poised to make a lasting impact in the gaming industry.
                        </p>
                    </div>
                </section>
                <p className="w-3/4  ml-5 text-lg font-normal text-gray-600">
                    At Cybermash Interactive, our games are infused with a unique vitality and honesty, stemming from a simple yet profound question: "What would be truly enjoyable and challenging?" This inquiry serves as our guiding principle, allowing us to stay focused on the essence of game development.<br/><br/>
                    In an industry where genres, demographics, marketing, and monetization strategies often take center stage, we choose to prioritize the fundamental aspects of game creation. We believe that the most captivating games arise from a genuine passion for gameplay and a commitment to craftsmanship.<br/><br/>
                    Our approach is straightforward: we strive to create games that we ourselves would eagerly play and be proud to work on for months and years. This dedication to our craft ignites our inspiration, yielding distinctive and engaging experiences. We confidently trust that our meticulous attention to detail and dedication to workmanship will speak for itself, allowing our games to resonate with players worldwide.
                </p>
            </div>
        </div>
    )
}

export default AboutUs

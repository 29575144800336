import { Link } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from "react"

function FooterButton({title, to, state}) {
    return (
        <Link className='block text-white font-extralight hover:underline' to={to} state={state}>
            {title}
        </Link>
    )
}

export default FooterButton
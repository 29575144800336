import LearnMoreButton from "../buttons/LearnMoreButton"

function LatestGameCard({title, desc, to, image, state}) {
    return (
        <div className="w-72 flex-box">
            <img className="w-full h-36 mb-2"
                crossorigin="anonymous"
                src={image}
                alt="game image"
            />
            <section className="w-full h-20 text-gray-600">
                <h2 className="text-xl font-semibold">{title}</h2>
                <p className="text-sm font-normal">{desc}</p>
            </section>
            <LearnMoreButton to={to} state={state}/>
        </div>
    )
}

export default LatestGameCard

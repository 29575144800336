import Logo from '../resources/images/logo_32x32.png'
import FooterButton from './footer/FooterButton'
import { airhoundData, apacheQuestData, clashOfMiniTanksData, rogueMechData } from '../data/game-data'

function Footer() {

    function renderPipe(){
        return <p className='mx-4'>|</p>
    }

    return (
        <div className='m-auto w-3/4 max-w-5xl'>
            <div className="px-10 pt-10 m-auto items-center bg-gray-500">
                <div className='mb-8 flex items-center'>
                    <img className='mr-3' src={Logo} alt='Logo' />
                    <h1 className='text-2xl font-bold italic text-amber-500 drop-shadow-2xl'>Cybermash Interactive</h1>
                </div>

                <div className='w-full flex tracking-wide text-white leading-[5px]'>
                    <div className='w-72'>
                        <FooterButton title='Games' to='/games'/>
                        <FooterButton 
                            title={rogueMechData.title}
                            to={rogueMechData.to}
                            state={rogueMechData.state}
                        />
                        <FooterButton 
                            title={apacheQuestData.title}
                            to={apacheQuestData.to}
                            state={apacheQuestData.state}
                        />
                        <FooterButton 
                            title={clashOfMiniTanksData.title}
                            to={clashOfMiniTanksData.to}
                            state={clashOfMiniTanksData.state}
                        />
                        <FooterButton 
                            title={airhoundData.title}
                            to={airhoundData.to}
                            state={airhoundData.state}
                        />
                    </div>

                    <section className='w-72'>
                        <FooterButton title='Support' to='/support'/>
                        <FooterButton title='About Cybermash' to='/about' />
                    </section>
                </div>

                <div className='mt-10'>
                    <div className='pt-5 border-t border-gray-300 flex font-extralight text-white'>
                        <FooterButton title='Terms of Use' isNotBlock={true} to='/termsofuse'/>
                        {renderPipe()}
                        <FooterButton title='Privacy Policy' isNotBlock={true} to='/privacy-policy'/>
                        {renderPipe()}
                        <FooterButton title='Contact Us' isNotBlock={true} />
                    </div>
                </div>
            </div>
            <div className='flex m-auto items-center p-10 h-20 bg-gray-600'>
                <p className='text-zinc-400'>Copyright © 2012 Cybermash Interactive, Inc. - All Rights Reserved.</p>
            </div>
        </div>
    )
}

export default Footer
